import React from "react";
import Article from "./article";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

const Articles = ({ header, articles, filter, handleDelete }) => {

  return (
    <>
      <div>{filter?.map(f => <Chip
        style={{ marginRight: '0.5rem', background: '#209848', color: 'white' }}
        label={f}
        onDelete={handleDelete ? () => handleDelete(f) : ''}
      />)}</div>

      <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1rem' }}>
        {articles.map((edge, i) => <Article article={edge.node ? edge.node : edge} key={i} />)}
      </div>
    </>
  );
};

export default Articles;

import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import * as styles from "./article.module.css"

const Article = ({ article }) => {
    return (
        <Link className={styles.article} to={`/naujiena/${article.slug}`}>
            <div>
                {article?.image?.childImageSharp?.fixed &&
                    <Img
                        fixed={article.image.childImageSharp.fixed}
                        imgStyle={{ position: "static" }} className={styles.articleImage}
                    />}
            </div>
            <div className={styles.articleTextContainer}>
                <div className={styles.articleDate}>
                    {article.publishedAt}
                </div>
                <div className={styles.articleHeader}>
                    {article.title}
                </div>
                {article.category && article.category.name && <div className={styles.articleCategory}>
                    {article.category.name}
                </div>}
                <div className={styles.articleReadMore}>
                    <div>
                        Skaityti daugiau
                    </div>
                    <div className="articleReadMoreIcon">
                        <MdChevronRight size={30} />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Article;
